<template>
	<div class="uploadFile h100">
		<div class="uploadFile-content">
			<div style="margin: 0 20%">
				<p class="title" :class="{ activeClass: checkVal == '2' }" @click="checkVal = '2'">添加培训</p>
				<p class="title" :class="{ activeClass: checkVal == '3' }" @click="checkVal = '3'">添加课程</p>
				<p class="line"></p>
			</div>
			<div class="fixHeight">
				<div class="formClass">
					<el-form :model="formInline" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
						<el-form-item label="讲堂名称" prop="title" class="w40 mr28">
							<el-input v-model.trim="formInline.title" disabled></el-input>
						</el-form-item>
						<el-form-item label="讲堂类别" prop="hallType" class="w40 mr0">
							<el-input v-model.trim="formInline.hallType" disabled></el-input>
						</el-form-item>
						<el-form-item label="讲堂编号" prop="serialNumber" class="w40 mr28">
							<el-input v-model.trim="formInline.serialNumber" disabled></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="uploadFile-tables">
					<AddTraning v-if="checkVal == '2'"></AddTraning>
					<AddCourse v-else></AddCourse>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			checkVal: '2',
			formInline: {
				title: '',
				hallType: '',
				serialNumber: '',
			},
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				trainingSubject: [{ required: true, message: '请输入', trigger: 'blur' }],
				courseCode: [{ required: true, message: '请输入', trigger: 'blur' }],
			},
			courseTableHead: [],
			courseTableData: [],
			saveLoading: false,
			attachmentInfo: {},
		};
	},
	components: {
		Table: () => import('@/components/Table/table'),
		AddTraning: () => import('./addTraning/index.vue'),
		AddCourse: () => import('./addCourse/index.vue'),
	},
	mounted() {
		this.checkVal = this.$route.query?.type || '2';
		this.formInline.title = this.$route.query?.title || '';
		this.formInline.hallType = this.$route.query?.hallType || '';
		this.formInline.serialNumber = this.$route.query?.serialNumber || '';
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
.uploadFile {
	background-color: #fff;
	border-radius: 2px;
	padding-top: 20px;
	margin: 16px 16px 0;
	overflow: auto;
	&-content {
		margin: 0 auto;
		.fixHeight {
			width: 100%;
			overflow: auto;
			padding: 0 20%;
		}
		.title {
			font-size: 16px;
			color: #333333;
			display: inline-block;
			width: 66px;
			margin-right: 28px;
			line-height: 44px;
			cursor: pointer;
		}
		.activeClass {
			color: #1db9b1;
			border-bottom: 3px solid #1db9b1;
			box-sizing: border-box;
		}
		.line {
			width: 100%;
			height: 2px;
			background-color: #f3f3f3;
		}
		.formClass {
			margin-top: 40px;
			/deep/ .el-form-item {
				margin-bottom: 28px;
				.el-form-item__label {
					line-height: 14px;
					height: 14px;
					margin-bottom: 8px;
				}
			}
		}
		.uploadFile-tables {
			margin-top: 32px;
			.main-content-title {
				line-height: 48px;
				display: flex;
				justify-content: space-between;
				.el-button {
					line-height: 32px;
					padding: 0 16px;
					height: 32px;
					margin-top: 8px;
				}
			}
		}
	}

	/deep/ .el-table {
		.cell {
			line-height: 20px;
			text-align: center;
		}
		tr th {
			background-color: #f5f5f5;
		}
		.el-table__cell {
			color: #333 !important;
			font-weight: normal;
			height: 20px;
			line-height: 20px;
			padding: 16px 0;
			.caret-wrapper {
				height: 20px;
				.ascending {
					top: -3px;
				}
				.descending {
					bottom: 1px;
				}
			}
		}
		.inputWidth {
			width: 110px;
			position: relative;
		}
		.errorClass {
			.el-input__inner {
				border-color: #f56c6c !important;
			}
		}
		.tipsClass {
			position: absolute;
			color: #f56c6c;
			text-align: left;
			font-size: 12px;
			line-height: 12px;
			left: 14px;
			top: 45px;
		}
	}
	/deep/ .w40 {
		width: calc(50% - 14px);
		display: inline-block;
	}
	/deep/ .m28 {
		margin-right: 28px;
	}
	/deep/ .mr0 {
		margin-right: 0 !important;
	}
}
</style>